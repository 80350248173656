import request from '../utils/request'
import qs from 'qs'
/**
 * 根据户号获取账户信息
 * @param accessLink
 * @returns {*}
 */
export function accountInfo(accountNo){
    return request({
        url: 'user/accountInfo.do',
        method: 'post',
        data: qs.stringify({accountNo: accountNo})
    })
}

/**
 * 获取绑定列表
 * @returns {*}
 */
export function bindList(){
    return request({
        url: 'user/bindList.do',
        method: 'post'
    })
}

/**
 * 取消绑定户号
 * @param id
 * @param remark
 * @returns {*}
 */
export function unBindAccount(id,remark){
    return request({
        url: 'user/unBindAccount.do',
        method: 'post',
        data: qs.stringify({id: id,remark:remark})
    })
}

/**
 * 绑定户号
 * @param refereeCode
 * @param accountNo
 * @param accountName
 */
export function addBindAccount(refereeCode,accountNo,accountName){
    return request({
        url: 'user/addNewAcount.do',
        method: 'post',
        data: qs.stringify({refereeCode: refereeCode,accountNo:accountNo,accountName:accountName})
    })
}