<template>
  <div class="container">
    <div class="header-container">
      <header>
        <van-nav-bar
            :title="title"
            left-text="返回"
            right-text="新增户号"
            left-arrow
            @click-left="onBack"
            @click-right="onAddAccount"
        />
      </header>
    </div>
    <div class="section-box" style="padding: 5px">
        <van-cell v-for="(item,itemIndex) in bindWaterList" v-bind:key="itemIndex"   @click="onBindAccountIndex(itemIndex)" class="van-cell" :style="bindIndex==itemIndex ? 'background: #efefef;':''">
          <template #title>
            <van-row>
              <van-col span="20"> 户号：{{item.accountNo}}</van-col>
              <van-col span="4"><van-button type="primary" size="small" @click="unBindConfirm(item)">解绑</van-button></van-col>
            </van-row>
          </template>
          <template #label>
            <div>地址：{{item.address}}</div>
            <div>户名：{{item.accountName}}</div>
          </template>
          <template #right-icon >
            <van-icon name="success" color="green" v-if="itemIndex==bindIndex && check == 1"/>
            <van-icon name="success" color="#fff" v-if="itemIndex!=bindIndex && check == 1"/>
          </template>
        </van-cell>
    </div>
    <!--取消户号绑定-->
    <van-overlay :show="show" @click="show = false">
      <div class="pop-wrapper" @click.stop>
        <div class="pop-block">
          <div class="pop-block-header">您确定要解除改水号的绑定吗？</div>
          <div style="text-align: left;padding: 5px;font-size: 14px;">请选择取消绑定原因：
          <van-radio-group v-model="reason" class="van-radio-group">
            <div v-for="(item,index) in reasons" v-bind:key="index">
              <van-radio :name="item.name">{{ item.name }}</van-radio>
            </div>
          </van-radio-group>
          </div>
          <div class="pop-block-footer">
            <van-row>
              <van-col span="12" style="border-right:1px grey solid;">
                <van-button type="default" block native-type="button" @click="show = false;reason = ''">取消</van-button>
              </van-col>
              <van-col span="12"><van-button type="info" block @click="unBind">确认</van-button></van-col>
            </van-row>
          </div>
        </div>
      </div>
    </van-overlay>
    <!--新增户号绑定-->
    <van-overlay :show="addShow" @click="addShow = false">
      <div class="pop-wrapper" @click.stop>
        <div class="pop-block">
          <van-form @submit="addBindAccount">
          <div class="pop-block-header">户号绑定</div>
          <div style="text-align: left;padding: 5px;">
            <van-field
                v-model="accountNo"
                type="digit"
                name="accountNo"
                label="户号"
                label-width="40"
                placeholder="请输入户号"
                required
                :rules="[{ required: true, message: '' }]"
            />
            <van-field
                v-model="accountName"
                name="accountName"
                label="户名"
                label-width="40"
                placeholder="请输入户名"
                required
                :rules="[{ required: true, message: '' }]"
            />
            <div style="font-size: 14px;line-height: 25px;">
              尊敬的用户：
              <div style="padding-left: 10px">请输入您水费通知单上的户号、户名进行户号绑定，并确保输入信息一致。</div>
              <div style="padding-left: 10px">如有疑问，请拨打96106进行咨询。</div>
            </div>
          </div>
          <div class="pop-block-footer">
            <van-row>
              <van-col span="12" style="border-right:1px grey solid;">
                <van-button type="default" block native-type="button" @click="addShow = false;accountNo='';accountName=''">取消</van-button>
              </van-col>
              <van-col span="12"><van-button type="info" block>绑定</van-button></van-col>
            </van-row>
          </div>
          </van-form>
        </div>
      </div>
    </van-overlay>
    <Loading :loding-txt="lodingTxt" :is-show="isShow"/>
  </div>
</template>

<script>
import Loading from "../../components/logding";
import {getKey, setKey} from "../../utils/cookie";
import {bindList,unBindAccount,addBindAccount} from "@/api/user";

export default {
  name: "bindAccount",
  components: { Loading },
  data() {
    return {
      isShow: false,
      lodingTxt: '加载中...',
      title: '户号绑定列表',
      bindWaterList:[],
      reason:'',
      reasons:[],
      check: this.$route.query.check,
      bindIndex: -1,
      curWaterList:[],
      chechItem:{},
      accountNo:'',
      accountName:'',
      isDisBind:false,
      show: false,
      addShow:false
    }
  },
  created() {
    this.bindList()
    if (this.$route.query.check == 1)
      this.bindIndex = parseInt(getKey(process.env.VUE_APP_H5_BIND_CHECK_INDEX) || '0')
  },
  methods: {
    /**
     * 加载绑定户号列表
     */
    bindList(){
      const that = this
      that.lodingTxt = '加载中...'
      that.isShow = true
      bindList().then(response => {
        that.isShow = false
        if (response.code == process.env.VUE_APP_SUCCESS_CODE) {
          that.bindWaterList = response.data.bindWaterList
          if (that.bindWaterList.size > 0) {
            //更新缓存
            setKey(process.env.VUE_APP_USER_IS_BIND, 1)
            setKey(process.env.VUE_APP_USER_BIND_NUMBER, 1)
          }else{
            setKey(process.env.VUE_APP_USER_IS_BIND, 0)
            setKey(process.env.VUE_APP_USER_BIND_NUMBER, 0)
          }
          that.reasons = response.data.reasonList
        }else{
          that.$toast(response.message)
        }
      }).catch(() => {
        that.isShow = false
        that.$toast('服务器错误，请稍后再试！')
      })
    },
    onBack: function () {
      window.history.back();
    },
    /**
     * 解绑户号确认
     * @param e
     */
    unBindConfirm(e){
      this.chechItem = e
      this.isDisBind = true
      this.show = true
      console.log(e)
    },
    /**
     * 取消绑定
     */
    unBind(){
      const that = this
      if (that.reason == ''){
        that.$toast('请选择解绑原因！')
      }
      that.lodingTxt = '解绑中...'
      that.isShow = true
      unBindAccount(that.chechItem.id,that.reason).then(response => {
        console.log(response)
        that.isShow = false
        that.$toast(response.message)
        if (response.code == process.env.VUE_APP_SUCCESS_CODE) {
          that.isDisBind = false
          that.show = false
          that.reason = ''
          that.chechItem = {}
          that.bindList()
        }
      }).catch(() => {
        that.isShow = false
        that.$toast('服务器错误，请稍后再试！')
      })
    },
    onBindAccountIndex(e){
      if (this.check == 1 && !this.isDisBind) {
        console.log(e)
        if (this.bindIndex != e){
          this.bindIndex = e
          console.log('选择值:'+this.bindIndex)
          setKey(process.env.VUE_APP_H5_BIND_CHECK_INDEX, e)
        }
        this.onBack()
      }
    },
    /**
     * 添加绑定
     */
    onAddAccount(){
      this.addShow = true
    },
    addBindAccount(){
      const that = this
      that.lodingTxt = '绑定中...'
      that.isShow = true
      addBindAccount('',that.accountNo,that.accountName).then(response => {
        console.log(response)
        that.isShow = false
        that.$toast(response.message)
        if (response.code == process.env.VUE_APP_SUCCESS_CODE) {
          that.addShow = false
          that.accountNo = ''
          that.accountName = ''
          that.bindList()
        }
      }).catch(() => {
        that.isShow = false
        that.$toast('服务器错误，请稍后再试！')
      })
    }
  }
}
</script>

<style scoped>
.van-radio-group{line-height: 30px; padding: 5px;}
.van-radio-group div{line-height: 30px; height: 30px;}
</style>